<template>
  <div>
    <div class="banner">
      <div>Tu aliado financiero, siempre a tu alcance.</div>
      <img src="@/assets/banner.png" alt="" />
    </div>
    <div class="bgcolor"></div>
    <div class="solicitar-box">
      <div class="solicitar">
        <span>Solicitar un préstam</span>
        <div class="money-box">
          <span @click="moneyJian">
            <img src="@/assets/jian.png" alt="" />
          </span>
          <span class="money">
            <span class="tag">{{ moneyNum }}</span>
          </span>
          <span @click="moneyAdd">
            <img src="@/assets/jia.png" />
          </span>
        </div>
        <div class="date-box">
          <div
            v-for="(item, index) in days"
            :key="item"
            :class="daysIndex === index ? 'a-days' : ''"
            @click="daysIndex = index"
          >
            {{ item }}
          </div>
        </div>
        <div class="instantane" @click="donwload">
          <span>Préstamos Instantáne</span>
          <img src="@/assets/dianji.png" alt="" />
        </div>
      </div>
    </div>
    <p class="p-title">Pasos Simples</p>
    <div class="card-box">
      <div class="card-title-box" v-for="item in desc" :key="item.icon">
        <div class="card-title">
          <img :src="item.numIcon" alt="" />
          <span>{{ item.title }}</span>
        </div>
        <p>{{ item.desc }}</p>
        <img :src="item.icon" alt="" />
      </div>
    </div>
    <p class="p-title">Por qué elegirnos？</p>
    <div class="card-box" style="gap: 40px; margin-bottom: 100px">
      <div class="elegirnos" v-for="item in elegirnos" :key="item.desc">
        <img :src="item.icon" alt="" />
        <span>{{ item.desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},

  data() {
    return {
      moneyNum: 1000,
      desc: [
        {
          title: 'Ten una cuenta que',
          desc: 'Inicia sesión o regístrate, ten una cuenta que sea tuya y sigue los pasos a continuación.',
          numIcon: require('@/assets/one.png'),
          icon: require('@/assets/card-1.png'),
        },
        {
          title: 'Ten una cuenta que',
          desc: 'Completa unos cuantos pasos de información para brindarte un servicio personalizado.',
          numIcon: require('@/assets/two.png'),
          icon: require('@/assets/card-2.png'),
        },
        {
          title: 'Ten una cuenta que',
          desc: 'Elige el monto y el plazo, y recibirás el dinero de inmediato!',
          numIcon: require('@/assets/san.png'),
          icon: require('@/assets/card-3.png'),
        },
      ],
      elegirnos: [
        {
          icon: require('@/assets/wenjian.png'),
          desc: 'Se requiere muy poca información',
        },
        {
          icon: require('@/assets/shou.png'),
          desc: 'Protección total de su información',
        },
        {
          icon: require('@/assets/time.png'),
          desc: 'Atención al cliente 24 horas en línea',
        },
        {
          icon: require('@/assets/qian.png'),
          desc: 'Desembolsos súper rápidos',
        },
      ],
      days: ['91 Dìas', '121 Dìas', '180 Dìas'],
      daysIndex: 0,
    };
  },
  methods: {
    moneyAdd() {
      if (this.moneyNum < 30000) {
        this.moneyNum += 100;
      }
    },
    moneyJian() {
      if (this.moneyNum > 0) {
        this.moneyNum -= 100;
      }
    },
    donwload() {
      window.open(
        'https://play.google.com/store/apps/details?id=com.cashmxn.loan.finance.app'
      );
    },
  },
};
</script>

<style>
.banner {
  box-sizing: border-box;
  height: 416px;
  background: #ffe0d4;
  padding: 0 360px;
  padding-top: 176px;
  position: relative;
}

.banner div {
  width: 665px;
  font-weight: 400;
  font-size: 56px;
  color: #fa7f32;
}
.banner img {
  width: 480px;
  height: 400px;
  position: absolute;
  right: 360px;
  bottom: -80px;
}
.bgcolor {
  width: 100%;
  height: 240px;
  background-color: #fee9de;
}
.solicitar-box {
  margin-top: -80px;
  display: flex;
  justify-content: center;
}
.solicitar {
  box-sizing: border-box;
  width: 1200px;
  height: 440px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(250, 100, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.solicitar > span {
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  font-style: normal;
  margin-bottom: 35px;
}

.money-box {
  display: flex;
  align-items: center;
  width: 400px;
  margin-bottom: 24px;
}
.money-box > span:first-child {
  width: 64px;
  height: 64px;
  background: #ffffff;
  border-radius: 8px 0px 0px 8px;
  border: 2px solid #fa7d32;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.money-box > span:first-child img {
  width: 32px;
  height: 6px;
}
.money-box > span:last-child {
  width: 64px;
  height: 64px;
  background: #fa7d32;
  border-radius: 0px 8px 8px 0px;
  border: 2px solid #fa7d32;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.money-box > span:last-child img {
  width: 32px;
  height: 32px;
}
.money {
  flex: 1;
  background-color: #f6f8fa;
  height: 64px;
  text-align: center;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.tag {
  position: relative;
}
.tag::after {
  content: '$';
  font-size: 20px;
  position: absolute;
  left: -15px;
  font-weight: 600;
  bottom: 5px;
}
.date-box {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin-bottom: 48px;
}
.date-box div {
  width: 120px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  background: #f6f8fa;
  border-radius: 8px;
  font-weight: 400;
  font-size: 20px;
  color: #787878;
  cursor: pointer;
}
.instantane {
  width: 400px;
  height: 74px;
  line-height: 74px;
  text-align: center;
  background: linear-gradient(134deg, #fa9632 0%, #fa7d32 52%, #fa9632 100%);
  border-radius: 37px;
  border: 1px solid #fa9632;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  position: relative;
  cursor: pointer;
}
.instantane img {
  position: absolute;
  width: 96px;
  height: 88px;
  right: -20px;
  bottom: -40px;
}
.p-title {
  margin-top: 80px;
  margin-bottom: 44px;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  color: #565656;
}
.card-box {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.card-box .card-title-box {
  width: 380px;
  height: 180px;
  background: #ffffff;
  border-radius: 16px;
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;
}
.card-title-box p {
  width: 245px;
  height: 100px;
  font-weight: 400;
  font-size: 18px;
  color: #787878;
  line-height: 25px;
}
.card-title-box > img {
  width: 118px;
  height: 88px;
  position: absolute;
  right: 20px;
  bottom: 0;
}
.card-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
}
.card-title img {
  width: 37px;
  height: 30px;
  margin-right: 12px;
}
.card-title span {
  font-weight: 600;
  font-size: 20px;
  color: #565656;
}
.elegirnos {
  width: 270px;
  height: 120px;
  background: #ffffff;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
}
.elegirnos img {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}
.elegirnos span {
  font-weight: 400;
  font-size: 18px;
  color: #787878;
}
.footer-1 {
  background-color: #ffffff;
  padding: 30px 360px;
  display: flex;
  align-items: center;
  box-shadow: 0px -8px 16px 0px rgba(250, 100, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer-1 img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  cursor: pointer;
}
.footer-1 p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  line-height: 45px;
}
.footer-1 span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #787878;
  line-height: 28px;
}
.footer-2 {
  background-color: #ffffff;
  padding: 40px 360px;
  display: flex;
  justify-content: space-between;
}
.footer-2 img {
  width: 96px;
  height: 40px;
}
.footer-2 div > p {
  font-weight: 500;
  font-size: 16px;
  color: #787878;
  margin-bottom: 5px;
}
.footer-2 > p {
  font-weight: 400;
  font-size: 20px;
  color: #0064fa;
  cursor: pointer;
}

.a-days {
  background-color: #fa7d32 !important;
  color: white !important;
}
</style>
